<template>
<b-card v-if="packages" no-body class="card-company-table">
    <b-table :items="packages" responsive :fields="fields" class="mb-0">
        <!-- Id -->
        <template #cell(id)="data">
            <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
        </template>

        <!-- CreatedAt Time -->
        <template #cell(createdAt)="data">
            <span class="font-weight-bolder mb-12">{{
          data.item.createdAt | moment("DD.MM.YYYY hh:mm")
        }}</span>
        </template>

        <!-- Status -->
        <template #cell(isActive)="data">
            <b-badge pill :variant="data.item.isActive ? 'light-success' : 'light-danger'">
                {{ data.item.isActive ? "AKTİF" : "PASİF" }}
            </b-badge>
        </template>

        <!-- Price -->
        <template #cell(price)="data">
            <b-badge pill variant="light-success"> {{ data.item.price }}₺ </b-badge>
        </template>

        <template #cell(actions)="data">
            <div class="text-nowrap">
                <feather-icon :id="`package-row-${data.item.id}-status-icon`" icon="EyeIcon" size="16" class="mx-1" />
                <b-tooltip title="Paketi Aktif/Pasif Hale Getir. Bu İşlem kullanan FİRMALARI ETKİLER!!" :target="`package-row-${data.item.id}-status-icon`" />

                <feather-icon :id="`package-row-${data.item.id}-edit-icon`" icon="EditIcon" size="16" class="mx-1" @click="editDetails(data.item)" />
                <b-tooltip title="Paket Düzenle" :target="`package-row-${data.item.id}-edit-icon`" />

                <feather-icon :id="`package-row-${data.item.id}-detail-icon`" icon="MoreHorizontalIcon" size="16" class="mx-1" @click="openDetails(data.item)" />
                <b-tooltip title="Paket Detayları" :target="`package-row-${data.item.id}-detail-icon`" />
            </div>
        </template>
    </b-table>

    <!--Open Package Edit-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="addPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Yeni Paket Oluştur</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <!-- From -->
                <b-form-group label="Kodu" label-for="from">
                    <b-form-input id="from" type="text" v-model="addPackageRequest.code" />
                </b-form-group>

                <!-- To -->
                <b-form-group label="Adı" label-for="to">
                    <b-form-input id="to" trim type="text" v-model="addPackageRequest.name" />
                </b-form-group>

                <!-- Subject -->
                <b-form-group label="Fiyatı" label-for="subject">
                    <b-form-input id="subject" type="number" v-model="addPackageRequest.price" />
                </b-form-group>

                <!-- Message -->
                <b-form-group label="Açıklaması" label-for="message">
                    <b-form-textarea id="message" rows="12" trim v-model="addPackageRequest.definition" />
                </b-form-group>

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="addPackage">
                        Ekle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>
    </b-sidebar>

    <!--Open Package Modules-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="detailPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Paket Detayları</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <div class="card-transaction px-2 py-1">
                <div class="transaction-item" @click="addModulePanel = true">
                    <b-media no-body>
                        <b-media-aside>
                            <b-avatar rounded size="42" variant="light-success">
                                <feather-icon size="18" icon="PlusIcon" />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body>
                            <h6 class="transaction-title">Yeni Modül Ekle</h6>
                            <small>Var Olan Modüllerden Pakete Modül Ekle</small>
                        </b-media-body>
                    </b-media>
                    <div class="font-weight-bolder text-danger"></div>
                </div>
                <div class="transaction-item" v-for="modul in modules" :key="modul.id">
                    <b-media no-body>
                        <b-media-aside>
                            <b-avatar rounded size="42" variant="light-primary">
                                <feather-icon size="18" icon="PocketIcon" />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body>
                            <h6 class="transaction-title">
                                {{ modul.module.name }}
                            </h6>
                            <small> {{ modul.module.code }}</small>
                        </b-media-body>
                    </b-media>
                    <div class="font-weight-bolder text-danger">
                        {{ modul.limit }}
                    </div>
                </div>
            </div>
        </template>
    </b-sidebar>

    <!--Open Package Edit-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="editPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Düzenle</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <!-- From -->
                <b-form-group label="Kodu" label-for="from">
                    <b-form-input id="from" type="text" v-model="selectedPackage.code" />
                </b-form-group>

                <!-- To -->
                <b-form-group label="Adı" label-for="to">
                    <b-form-input id="to" trim type="text" v-model="selectedPackage.name" />
                </b-form-group>

                <!-- Subject -->
                <b-form-group label="Fiyatı" label-for="subject">
                    <b-form-input id="subject" type="number" v-model="selectedPackage.price" />
                </b-form-group>

                <!-- Message -->
                <b-form-group label="Açıklaması" label-for="message">
                    <b-form-textarea id="message" rows="12" trim v-model="selectedPackage.definition" />
                </b-form-group>

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="hide">
                        Güncelle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>
    </b-sidebar>

    <!--Add Module For Package--->
    <b-modal title="Yeni Modül Ekle" ok-title="Ekle" ok-only cancel-variant="outline-secondary" v-model="addModulePanel" @ok="addPackageModules">
        <b-form>
            <b-form-group label="Eklemek İstediğiniz Modül" label-for="vue-select">
                <v-select id="vue-select" v-model="addModuleRequest.selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :options="serverModules" />
            </b-form-group>
            <b-form-group label="Limit" label-for="zip-code">
                <b-form-input id="zip-code" type="number" placeholder="Modül İçin Belirtilecek Limit" v-model="addModuleRequest.limit" />
            </b-form-group>
        </b-form>
    </b-modal>

</b-card>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BCardText,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BTooltip,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardText,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BTooltip,
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        vSelect,
        ToastificationContent
    },
    data() {
        return {
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'createdAt', label: 'Kayıt Tarihi' },
                { key: 'isActive', label: 'Durum' },
                { key: 'code', label: 'Kodu' },
                { key: 'name', label: 'Adı' },
                { key: 'definition', label: 'Açıklaması' },
                { key: 'price', label: 'Fiyatı' },
                { key: 'actions', label: 'İşmeler' },
            ],
            packages: [],
            selectedPackage: {},
            addPanel: false,
            addPackageRequest: {
                name: '',
                definition: '',
                code: '',
                price: 0
            },
            modules: [],
            detailPanel: false,
            editPanel: false,
            addModulePanel: false,
            addModuleRequest: {
                selected: {},
                limit: 0
            },
            serverModules: []
        }
    },
    mounted() {
        this.$root.$on('AddButtonClick', () => {
            this.addPanel = true;
        });
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            var packages = await this.$http.get("Admin/Package/Packages");
            this.packages = packages.data.data;
            var serverModules = await this.$http.get("Admin/Package/Modules");
            this.serverModules = serverModules.data.data;
        },
        async openDetails(val = null) {
            if (val == null)
                return;
            this.selectedPackage = val;
            var modules = await this.$http.get("Admin/Package/PackageModules?packageId=" + this.selectedPackage.id);
            this.modules = modules.data.data;
            this.detailPanel = true;
        },
        async editDetails(val = null) {
            if (val == null)
                return;
            this.selectedPackage = val;
            this.editPanel = true;
        },
        async addPackageModules() {
            //console.log(this.addModuleRequest);
            if (this.addModuleRequest.selected == null)
                return;
            if (this.addModuleRequest.limit == null)
                return;

            this.addModuleRequest.packageId = this.selectedPackage.id;
            this.addModuleRequest.moduleId = this.addModuleRequest.selected.id;
            this.addModuleRequest.limit = parseInt(this.addModuleRequest.limit);
            await this.$http.post("Admin/Package/AddPackageModule", this.addModuleRequest);
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Bildirim',
                    icon: 'BellIcon',
                    text: 'İşlem Başarılı.',
                    variant: 'success'
                },
            });
            this.openDetails(this.selectedPackage);
        },
        async addPackage() {
            this.addPackageRequest.price = parseFloat(this.addPackageRequest.price);
            await this.$http.post("Admin/Package/AddPackage", this.addPackageRequest);
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Bildirim',
                    icon: 'BellIcon',
                    text: 'İşlem Başarılı.',
                    variant: 'success'
                },
            });
            this.getData();
            this.addPanel = false;
        }
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style><style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
